import React from "react";
import styled from "styled-components";
import { TitleStyles } from "./ReusableStyles";
import emailjs from "emailjs-com";

const sendEmail = (e) => {
  e.preventDefault();
  emailjs
    .sendForm(
      "service_l85jgqp",
      "template_gt3lne9",
      e.target,
      "user_oEFEjNM3NftFo8RPUv57m"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  alert("Thanks for your feedback!");
  e.target.reset();
};

export default function Newsletter() {
  return (
    <Section id="refer">
      <div className="title">
        <h1>
          <span style={{color: '#77be41'}}>Referral</span> Program
        </h1>
        <p>
          Earn a $100 referral! Anyone who refers us to a friend is eligible to
          receive a $100 referral check. Just refer us to a friend; if your
          referral results in a new Elite Vending location, you will receive a
          $100 check courtesy of Elite Vending Services. How easy is that! All
          we need is a contact name, email address or phone number and we’ll do
          the rest. Same company referrals do not apply.
        </p>
      </div>
      <div className="container">
        {/* <input type="text" placeholder="Search for food ..." />
        <button>Search</button> */}
        <form onSubmit={sendEmail}>
          <input type="text" name="email" placeholder="Email" />
          <input type="text" name="message" placeholder="Message" />
          <button type="submit">Submit</button>
        </form>
      </div>
    </Section>
  );
}

const Section = styled.section`
  border: 0.01rem solid black;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  ${TitleStyles};
  .container {
    background: linear-gradient(to right, #77be41, #77be41, #012e45);
    padding: 0.3vw;
    input {
      border: none;
      padding: 1.5rem 8rem 1.5rem 1rem;
      font-size: 1.3rem;
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 1rem 5rem;
      background-color: transparent;
      border: none;
      font-size: 1.3rem;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        letter-spacing: 0.6rem;
        padding: 1rem 4.7rem;
      }
    }
  }
  @media screen and (min-width: 260px) and (max-width: 1080px) {
    .container {
      padding: 0.8rem;
      border-radius: 0.5rem;
      input {
        width: 75vw;
        padding: 0.5rem;
        border-radius: 0.5rem;
      }
      button {
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.5rem;
        &:hover {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
`;
