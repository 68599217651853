import React, { useState } from "react";
import styled from "styled-components";
import product1 from "../assets/drink-vend.jpg";
import product2 from "../assets/product2.jpg";
import product3 from "../assets/product3.jpg";
import product4 from "../assets/product4.jpg";
import { imageZoomEffect, TitleStyles } from "./ReusableStyles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
//Drinks
import celcius from "./../assets/drinks/celcius-image.png";
import gatorade from "./../assets/drinks/gatorade-image.jpeg";
import lipton from "./../assets/drinks/liptontea-image.png";
import crush from "./../assets/drinks/crush-image.png";
import monster from "./../assets/drinks/monsterenergy-image.png";
import powerade from "./../assets/drinks/powerade-image.png";
import redbull from "./../assets/drinks/redbull-image.png";
import vitamin from "./../assets/drinks/vitaminwater-image.png";
import variety from "./../assets/drinks/varietypop-image.png";
import varietycanada from "./../assets/drinks/varietycanada-image.png";
//Snacks
import almonds from "./../assets/snacks/almonds-image.png";
import jerky from "./../assets/snacks/beefjerky-image.png";
import butterfinger from "./../assets/snacks/butterfinger-image.png";
import ruffles from "./../assets/snacks/chedderruffles-image.png";
import cheetos from "./../assets/snacks/cheetos-image.png";
import cheetoshot from "./../assets/snacks/cheetoshot-image.png";
import cfritos from "./../assets/snacks/chilifritos-image.png";
import cdonuts from "./../assets/snacks/chocolatedonuts-image.png";
import croll from "./../assets/snacks/cinnamonroll-image.png";
import cliffbars from "./../assets/snacks/cliffbars-image.png";
import coolranch from "./../assets/snacks/coolranch-image.png";
import fritos from "./../assets/snacks/fritosplain-image.png";
import fruitsnacks from "./../assets/snacks/fruitsnacks-image.png";
import grippos from "./../assets/snacks/grippos-image.png";
import honeybuns from "./../assets/snacks/honeybuns-image.png";
import kindbars from "./../assets/snacks/kindbars-image.png";
import laysclassic from "./../assets/snacks/laysclassic-image.png";
import mmchocolate from "./../assets/snacks/mmchocolate-image.png";
import muffins from "./../assets/snacks/muffins-image.png";
import nachocheese from "./../assets/snacks/nachocheese-image.webp";
import peanutmm from "./../assets/snacks/peanutmm-image.png";
import peanuts from "./../assets/snacks/peanuts-image.png";
import popcorn from "./../assets/snacks/popcorn-image.png";
import pdonuts from "./../assets/snacks/pdonuts-image.png";
import pretzelchip from "./../assets/snacks/pretzelchip-image.png";
import reeses from "./../assets/snacks/reeses-image.png";
import ricekrispies from "./../assets/snacks/ricekrispies-image.png";
import skittles from "./../assets/snacks/skittles-image.png";
import snickers from "./../assets/snacks/snickers-image.png";
import takis from "./../assets/snacks/takis-image.png";
import veggiestraws from "./../assets/snacks/veggiestraws-image.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Products() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const data = [
    {
      image: product1,
      name: "Chicken Burger",
      price: "$22.4/pcs",
    },
    {
      image:
        "https://images.unsplash.com/photo-1566478989037-eec170784d0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
      name: "Toasted Bread",
      price: "$5.5/pcs",
    },
    {
      image:
        "https://images.unsplash.com/photo-1519944781502-4ac8148b55e9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
      name: "Egg Sandwich",
      price: "$8/pcs",
    },

    {
      image:
        "https://images.unsplash.com/photo-1578783204979-27a2e3de3746?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      name: "something",
      price: "$12.5/pcs",
    },
  ];
  return (
    <Section id="products">
      <div className="title">
        <h1>
          <span style={{ color: "#012e45" }}>Full Inventory</span>
        </h1>
      </div>
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Drinks" {...a11yProps(0)} />
              <Tab label="Snacks" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={celcius} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={gatorade} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={lipton} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={crush} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={monster} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={powerade} style={{ width: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={redbull} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={vitamin} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={variety} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={varietycanada}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={almonds} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={jerky} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={butterfinger}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={ruffles} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={cheetos} style={{ width: "100%", height: "auto" }} />
              </Grid>

              <Grid item xs={12} sm={2}>
                <img src={cdonuts} style={{ width: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={cfritos} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={cliffbars}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={croll} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={coolranch}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={fritos} style={{ width: "100%", height: "auto" }} />
              </Grid>

              <Grid item xs={12} sm={2}>
                <img
                  src={fruitsnacks}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
            </Grid>
       
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={grippos} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={honeybuns}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={kindbars} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={laysclassic}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={mmchocolate}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <img src={muffins} style={{ width: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={nachocheese} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={peanutmm} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={peanuts}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={popcorn} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={cheetoshot} style={{ width: "100%", height: "auto" }} />
              </Grid>

              <Grid item xs={12} sm={2}>
                <img src={pdonuts} style={{ width: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={pretzelchip} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={reeses} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img
                  src={ricekrispies}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={skittles} style={{ width: "100%", height: "auto" }} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={snickers} style={{ width: "100%", height: "auto" }} />
              </Grid>

              <Grid item xs={12} sm={2}>
                <img src={takis} style={{ width: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: "2%", color: "white", marginBottom: "4%" }}
            >
              <Grid item xs={12} sm={2}>
                <img src={veggiestraws} style={{ width: "100%", height: "auto" }} />
              </Grid>
              
            </Grid>
          </CustomTabPanel>
        </Box>
      </div>
      {/* <div className="products">
        {data.map((product) => {
          return (
            <div className="product">
              <div className="image">
                <img src={product.image} alt="" />
              </div>
              <h2>{product.name}</h2>
              <h3>{product.price}</h3>
            </div>
          );
        })}
      </div> */}
    </Section>
  );
}

const Section = styled.section`
  ${TitleStyles};
  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 3rem;
    .product {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      justify-content: center;
      align-items: center;
      h3 {
        color: #fc4958;
      }
      p {
        text-align: center;
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: 0.1rem;
      }
      ${imageZoomEffect};
      .image {
        max-height: 20rem;
        overflow: hidden;
        border-radius: 1rem;
        img {
          height: 20rem;
          width: 15rem;
          object-fit: cover;
        }
      }
      button {
        border: none;
        padding: 1rem 4rem;
        font-size: 1.4rem;
        color: white;
        border-radius: 4rem;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        background: linear-gradient(to right, #fc4958, #e85d04);
        text-transform: uppercase;
        &:hover {
          background: linear-gradient(to right, #e85d04, #fc4958);
        }
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    .products {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    .products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
